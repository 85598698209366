<template>
  <VContainer
    v-if="!loading"
    class="account-login"
    fluid
  >
    <VForm
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="login"
    >
      <header>
        <img
          class="logo"
          :src="(vendor.branding && vendor.branding.logo) ? `/${vendor.branding.logo}` : logotype"
          :alt="vendor.title"
          aria-hidden="true"
        >
        <h1 class="page-title">
          {{ localDocumentTitle }}
        </h1>
      </header>
      <VTextField
        v-model="username"
        :label="$t('usernames.one')"
        :placeholder="$t('usernames.one')"
        persistent-placeholder
        outlined
        autofocus
        name="username"
        :rules="[
          v => !!(v || '').trim().length || 'Это поле обязательно',
          v => (v || '').trim().length <= 254 || 'Логин должен быть не больше 254 символов'
        ]"
      />

      <VTextField
        v-model="password"
        :label="$t('passwords.one')"
        :placeholder="$t('passwords.one')"
        persistent-placeholder
        name="password"
        :type="isPasswordVisible ? 'text' : 'password'"
        outlined
        :rules="[
          v => !!(v || '').trim().length || 'Это поле обязательно',
          v => (v || '').trim().length >= 6 || 'Пароль должен быть не меньше 6 символов',
          v => (v || '').trim().length <= 254 || 'Пароль должен быть не больше 254 символов'
        ]"
        :append-icon="isPasswordVisible ? 'visibility_off' : 'visibility'"
        @click:append="isPasswordVisible = !isPasswordVisible"
      />

      <VCheckbox
        v-if="vendor.branding && vendor.branding.show_privacy_policy_approval && vendor.branding.privacy_policy_approval"
        v-model="privacy_policy_accepted"
        class="privacy-policy-checkbox"
        name="privacy_policy_accepted"
        value="1"
        :rules="[v => !!v || 'Чтобы продолжить, установите этот флажок']"
        required
      >
        <template #label>
          <div
            class="privacy-policy-title rte-content"
            @click.stop
            v-html="vendor.branding.privacy_policy_approval"
          />
        </template>
      </VCheckbox>

      <VBtn
        x-large
        type="submit"
        block
        color="primary"
        :loading="isSubmitting"
      >
        {{ $t('anonymous_sign_in.one') }}
      </VBtn>

      <footer class="text-center">
        <VCardText>
          <p v-if="vendor.branding.self_registration_enabled">
            {{ $t('anonymous_first_question.one') }} <RouterLink to="/register">
              {{ $t('anonymous_register.one') }}
            </RouterLink>
          </p>
          <RouterLink to="/reset">
            {{ $t('anonymous_forgot_password.one') }}
          </RouterLink>
        </VCardText>

        <VBtn
          v-if="vendor.branding.show_guest_online_shop_link"
          href="/shop"
          large
          block
          outlined
          color="success"
        >
          {{ $t('online_shop.one') }}
        </VBtn>

        <VDivider />
        <VCardText>
          <RouterLink
            v-if="vendor.branding.show_privacy_policy_link"
            :to="{ name: 'privacy-policy' }"
            target="_blank"
          >
            {{ $t('personal_data_processing_policy.one') }}
          </RouterLink>
        </VCardText>
      </footer>
    </VForm>
  </VContainer>
  <VProgressCircular
    v-else
    :size="50"
    color="primary"
    indeterminate
    class="app-loader"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import documentTitle from '@/mixins/documentTitle'
import logotype from '@/assets/images/uchi.svg'
import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'

export default {
  name: 'TheAccountLogin',
  mixins: [documentTitle],

  data () {
    return {
      documentTitle: 'anonymous_sign_in.one',
      logotype,
      valid: false,
      username: '',
      password: '',
      privacy_policy_accepted: 0,
      isPasswordVisible: false,
      loading: true,
      isSubmitting: false
    }
  },

  computed: {
    ...mapGetters({
      vendor: getters.VENDOR
    }),

    token () {
      return this.$route.params.token || ''
    }
  },

  created () {
    this.register()
  },

  methods: {
    ...mapActions({
      loginAccount: actions.LOGIN
    }),

    async login () {
      const form = this.$refs.form
      if (!form.validate()) { return }

      const formData = new FormData(form.$el)
      if (this.privacy_policy_accepted) {
        formData.append('privacy_policy_accepted', 1)
      }

      this.isSubmitting = true

      await this.loginAccount({
        token: this.token,
        body: formData
      })

      this.isSubmitting = false
    },

    async register () {
      if (this.token !== '') {
        await this.loginAccount({ token: this.token })
      }

      this.loading = false
    }
  }
}
</script>

<style lang="scss">
  .account-login {
    .privacy-policy-checkbox {
      margin-top: 0;

      .v-input__slot {
        align-items: flex-start;
      }
    }

    .privacy-policy-title {
      font-size: map-deep-get($headings, 'body-2', 'size');
      color: map-deep-get($material-light, 'text', 'primary');
    }
  }
</style>
